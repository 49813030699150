<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="goReplace"></div>
        <div class="center ellipsis">往期主题</div>
        <div class="right"></div>
      </div>
      <div class="section" v-if="adsGood.length > 0">
        <ImgDecypt class="tag-img" :src="item.background" v-for="item in adsGood[0].subTabs" :key="item.id" @click.native="toGoodVideo(item.id)"/>
        <!-- <ImgDecypt class="tag-img" :src="item.background" v-for="item in adsGood[0].subTabs" :key="item.id" @click.native="$router.replace({path:'/goodVideo',query:{id:item.id}})"/> -->
      </div>
      <nodata v-show="adsGood.length==0"/>
    </div>
  </div>
</template>
<script>
import { buried } from '@/api/index.js'
export default {
  data(){
    return{
      adsGood:JSON.parse(sessionStorage.getItem('appConfig')).pageCfg.filter(item => {return item.id == "spec_coll"}),//好片大赏往期主题
    }
  },
  methods:{
    goReplace(){
      this.$router.replace({path:'/goodVideo',query:{id:this.$route.query.id}});
    },
    toGoodVideo(id) {
      this.action(id);
      this.$router.replace({path:'/goodVideo',query:{id:id}});
    },
    async action(itemId) {
      let req = {
        location: 3,
        childLocation: itemId,
        type: 1
      }
      await this.$Api(buried, req);
    }
  },
}
</script>
<style lang="scss" scoped>
.search-all{
  height:100%;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}
.search-box{
  padding:0 16px;
  height:52px;
  align-items: center;
  background:$vermillion;
}
.left{
  width:22px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.center{
  font-size:20px;
  font-weight: bold;
  color:#ffffff;
}
.right{
  width:22px;
  color:#ffffff;
  font-size:14px;
}
.section{
  height: calc(100% - 52px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:6px 10px 30px;
  box-sizing: border-box;
}
.tag-img{
  width:100%;
  height:89px;
  border-radius:45px;
  margin-top:14px;
  overflow: hidden;
}
</style>
